import React, { useEffect } from "react";
import classes from "./BookingLists.module.css";
import BookingCards from "../BookingCards/BookingCards";

function BookingLists({ bookingData, timeStatus, fetchBookings }) {
  console.log("booking data is", bookingData);

  return (
    <div className={classes.bookingWrapper}>
      {bookingData
        ?.filter((data) => data?.itemStatus == timeStatus)
        .map((item) => (
          <BookingCards
            data={item}
            timeStatus={timeStatus}
            fetchBookings={fetchBookings}
          />
        ))}
    </div>
  );
}

export default BookingLists;
