import React, { useEffect, useState } from "react";
import classes from "./BookingsPage.module.css";
import ButtonGroup from "components/UI/ButtonGroup/ButtonGroup";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import BookingLists from "./BookingLists/BookingLists";
import { API_BASE_URL } from "configs/AppConfig";
import { useSelector } from "react-redux";
import moment from "moment";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";

function BookingsPage() {
  const [bookingData, setBookingData] = useState([]);
  const [filteredBookings, setFilteredBookings] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = JSON.parse(localStorage.getItem("Wedding_user"));
  console.log("user from local storage", user);

  const navigate = useNavigate();
  const [timeStatus, setTimeStatus] = useState("Pending");

  const convertDate = (date, value) => {
    const inputDate = new Date(date);

    // Define arrays for month names and day names
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const dayNames = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Get the day, month, and year from the input date
    const day = inputDate.getDate();
    const month = monthNames[inputDate.getMonth()];
    const year = inputDate.getFullYear();
    const dayOfWeek = dayNames[inputDate.getDay()];

    // Format the date string
    const formattedDate = `${day}  ${month} ${year},${dayOfWeek}`;
    if (value == "event_date") {
      return formattedDate;
    } else {
      return `${day}  ${month} ${year}`;
    }
  };

  const fetchBookings = async () => {
    setLoading(true);
    const response = await fetch(`${API_BASE_URL}booking/customer/bookings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    if (data?.success == true) {
      setLoading(false);
    }
    console.log("booking response", data?.data);
    const bookings = data?.data?.flatMap((booking) =>
      booking.items.map((item) => ({
        // date: item?.selectedDate,
        date: convertDate(item?.selectedDate, "event_date"),
        timing: `${moment(item?.start, "HH:mm")?.format("h:mm A")} - ${moment(
          item?.end,
          "HH:mm"
        )?.format("h:mm A")}`,
        title: item?.listingName,
        location: item?.listingLocation?.locationName,
        address: item?.listingAddress,
        details: item?.addOns?.map((item) => item?.name),
        orderDate: convertDate(booking?.createdAt, "order_date"),
        name: item?.customerName,
        price: Number(item?.price)?.toLocaleString("en-IN"),
        img: item?.listingImages[0],
        paymentStatus: item?.itemPaymentStatus,
        bookingStatus: booking?.bookingStatus,
        bookingNumber: booking?.bookingNumber,
        itemStatus: item?.itemStatus,
        id: booking?._id,
        itemId: item?._id,
      }))
    );
    setBookingData(bookings);

    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  useEffect(() => {
    fetchBookings();
  }, []);

  // useEffect(()=>{
  //   const filtered = bookingData?.map((item)=>item?.bookingStat)

  // },[bookingData])

  const handleButtonClick1 = () => {
    // Function for the first button
    console.log("Button 1 clicked");
    setTimeStatus("Pending");
  };

  const handleButtonClick2 = () => {
    // Function for the second button
    console.log("Button 2 clicked");
    setTimeStatus("Confirmed");
  };

  const handleButtonClick3 = () => {
    // Function for the second button
    console.log("Button 3 clicked");
    setTimeStatus("Completed");
  };

  const handleButtonClick4 = () => {
    // Function for the second button
    console.log("Button 4 clicked");
    setTimeStatus("Cancelled");
  };

  console.log("booking out", bookingData, timeStatus);

  return (
    <div className={classes.wishListContainer}>
      <div className={classes.headerSection}>
        <div className={classes.titleSection}>
          <h1>My Bookings</h1>
          <p>Stay organized and in control of your events</p>
        </div>
        <ButtonGroup
          buttonCount={4}
          buttonTexts={["Pending", "Confirmed", "Completed", "Cancelled"]}
          onClickFunctions={[
            handleButtonClick1,
            handleButtonClick2,
            handleButtonClick3,
            handleButtonClick4,
          ]}
        />
      </div>
      {/* <div className={classes.wishListCards}>
        <WishListCards />
      </div> */}
      {loading ? (
        <LoadingSpinner />
      ) : (
        <BookingLists
          fetchBookings={fetchBookings}
          bookingData={bookingData}
          timeStatus={timeStatus}
        />
      )}
    </div>
  );
}

export default BookingsPage;
