import React, { useState } from "react";
import classes from "./PaymentOptions.module.css";

function PaymentOptions() {
  const [selectedPayment, setSelectedPayment] = useState("Online Payment");

  return (
    <div className={classes.paymentOuter}>
      <h1>Payment Options</h1>
      <div className={classes.checkboxOptions}>
        <div className={classes.onlineAndOffline}>
          <p>Offline Payment</p>
          <img
            src={
              selectedPayment == "Offline Payment"
                ? "/img/icons/radioRed.svg"
                : "/img/icons/radioCircle.svg"
            }
            onClick = {()=>setSelectedPayment("Offline Payment")}
          />
        </div>
        <div className={classes.onlineAndOffline}>
          <p>Online Payment</p>
          <img src={
              selectedPayment == "Online Payment"
                ? "/img/icons/radioRed.svg"
                : "/img/icons/radioCircle.svg"
            }
            onClick = {()=>setSelectedPayment("Online Payment")}
             />
        </div>
      </div>
    </div>
  );
}

export default PaymentOptions;
