import React, { useEffect, useState } from "react";
import classes from "./SearchPage.module.css";
import SearchFilters from "./Search-filters/SearchFilters";
import SearchCards from "./SearchCards/SearchCards";
import CustomPagination from "components/CustomPagination/CustomPagination";
import CustomPaginations from "components/CustomPagination/CustomPagination";
import { useSelector } from "react-redux";
import { API_BASE_URL } from "configs/AppConfig";
import { useLocation } from "react-router-dom";
import { Spin } from "antd";

function SearchPage() {
  const [appliedFilterData, setAppliedFilterData] = useState([]);
  const [catArray, setCatArray] = useState([]);
  const [eventArray, setEventArray] = useState([]);
  const [ratingArray, setRatingArray] = useState([]);
  const [priceArray, setPriceArray] = useState([]);
  const [proximityData, setProximitydata] = useState(null);
  const [priceValue, setPriceValue] = useState(null);
  const [isVisible, setIsVisible] = useState(false);
  const [extractedValues, setExtractedValues] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  // const [filterValues, setFilterValues] = useState([]);
  const [appliedFilters, setAppliedFilters] = useState([]);
  const [loading, setLoading] = useState(false);
  const [venueData, setVenueData] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [loadExtractedValues, setLoadExtractedValues] = useState(false);
  const [error, setError] = useState(null);

  const location = useLocation();

  console.log("is visible", isVisible);

  const itemsCountPerPage = 20;

  const url = window.location.href;
  const user = useSelector((state) => state.user.user);
  const {latitude, longitude} = useSelector((state)=>state.filter);
  const [eventData, setEventData] = useState([]);
  

  useEffect(() => {
    setLoadExtractedValues(false);
    // Extract values using URLSearchParams
    const url = new URL(window.location.href);
    const params = new URLSearchParams(url.search);
    const values = {};

    params.forEach((value, key) => {
      if (key == "latitude" || key == "longitude" || key == "range") {
        values[key] = Number(value);
      } else {
        values[key] = value;
      }
    });      //create an object from href

    // Update state with extracted values
    setExtractedValues(values);       //store the created object to extracted values
    setLoadExtractedValues(true);
  }, [location.search]);

  const fetchcategories = async () => {
    const response = await fetch(`${API_BASE_URL}category/get/all/admin`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setCategoryData(
      data.data.map((item) => ({ label: item.name, value: item._id }))
    );
    // setCategoryValues(data.data);
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  const fetchVenues = async () => {
    setError(null);
    setVenueData([]);
    setLoading(true);
    console.log('search has query ?', location.search)

    const queryString = Object.keys(extractedValues)   //to pass query params to api from extracted values
      .map(
        (key) =>
          `${encodeURIComponent(key)}=${encodeURIComponent(
            extractedValues[key]
          )}`
      )
      .join("&");


    // if (latitude !== undefined) {
    //   queryString += `&latitude=${latitude}`;
    // }

    // if (longitude !== undefined) {
    //   queryString += `&longitude=${longitude}`;
    // }

    let response = {};

    if(latitude && longitude){

      response = await fetch(
        `${API_BASE_URL}listing/get/all?${queryString}&page=${currentPage}&limit=${itemsCountPerPage}&latitude=${latitude}&longitude=${longitude}&range=1000`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            // Authorization: `Bearer ${user.sessionToken}`,
          },
          // body: JSON.stringify({
          //   page: currentPage,
          //   limit: itemsCountPerPage,
  
          // }),
        }
      );

    }else{

    response = await fetch(
      `${API_BASE_URL}listing/get/all?${queryString}&page=${currentPage}&limit=${itemsCountPerPage}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          // Authorization: `Bearer ${user.sessionToken}`,
        },
        // body: JSON.stringify({
        //   page: currentPage,
        //   limit: itemsCountPerPage,

        // }),
      }
    );
    }

    const data = await response.json();
    if(data?.error){
      console.log('response not ok')
      setError("No Data Found");
      setLoading(false);
    }
    if(data?.success){
    setTotalCount(data?.total);
    // setCategoryData(
    //   data.data.map((item) => ({ label: item.name, value: item._id }))
    // );
    setVenueData(
      data.data.map((item) => ({
        title: item.name,
        city: item.location.locationName,
        state: "Kerala",
        price: item?.availableDates[0]?.intervals[0]?.price,
        strikePrice: 15000,
        review: 4.7,
        img: item.images[0],
        categoryName: item.categoryName,
        _id: item._id,
      }))
    );
    }
    console.log("listing response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }
    
    setLoading(false);
    return data;
  };

  useEffect(() => {
    fetchcategories();
  }, [user]);

  useEffect(() => {
    if(loadExtractedValues){
    fetchVenues();
    }
  }, [extractedValues,currentPage, latitude]);

  console.log("extracted values", extractedValues);

  //fetch events

  const fetchEvents = async () => {
    const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setEventData(
      data.data.map((item)=>({value: item.name, label: item.name}))
    );
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  const removeFilter = (name, value) => {
    console.log("name and value", name, value);
    setAppliedFilters(appliedFilters.filter((item) => item.name != name));

    if (name == "range") {
      setExtractedValues((prevValues) => ({
        ...prevValues,
        [name]: undefined, // Using square brackets to access the property dynamically
      }));
    } else {
      const updatedValues = { ...extractedValues };
      delete updatedValues[name];
      setExtractedValues(updatedValues);
    }
  };


  // console.log("applied filters are", appliedFilters);

  return (
    <>
      <div className={classes.searchOuter}>
        <SearchFilters
          appliedFilterData={appliedFilterData}
          setAppliedFilterData={setAppliedFilterData}
          removeFilter={removeFilter}
          catArray={catArray}
          setCatArray={setCatArray}
          eventArray={eventArray}
          setEventArray={setEventArray}
          ratingArray={ratingArray}
          setRatingArray={setRatingArray}
          proximityData={proximityData}
          setProximitydata={setProximitydata}
          priceValue={priceValue}
          setPriceValue={setPriceValue}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          categoryData={categoryData}
          extractedValues={extractedValues}
          setExtractedValues={setExtractedValues}
          // filterValues={filterValues}
          // setFilterValues={setFilterValues}
          appliedFilters={appliedFilters}
          setAppliedFilters={setAppliedFilters}
          eventData = {eventData}
        />

        <SearchCards
          appliedFilterData={appliedFilterData}
          setAppliedFilterData={setAppliedFilterData}
          removeFilter={removeFilter}
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          loading={loading}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          totalCount={totalCount}
          itemsCountPerPage={itemsCountPerPage}
          venueData = {venueData}
          setVenueData = {setVenueData}
          error = {error}
        />
      </div>
    </>
  );
}

export default SearchPage;
