import { createSlice } from '@reduxjs/toolkit';
import { USER_FILTER_VALUES } from 'helpers/constants';

const initialState = {
    latitude: null,
    longitude: null,
    address: null,
    place: null,
};

const filterSlice = createSlice({
    name: 'filter',
    initialState,
    reducers: {
        togglelocation(state, action) {
            if (action.payload.latitude) {
                state.latitude = action.payload.latitude;
            }
            if (action.payload.longitude) {
                state.longitude = action.payload.longitude;
            }
            if (action.payload.address) {
                state.address = action.payload.address;
            }
            if (action.payload.place) {
                state.place = action.payload.place;
            }
            const filterJson = JSON.stringify(state);
            // save the user data and expiration time in local storage
            localStorage.setItem(USER_FILTER_VALUES, filterJson);
        },
        autoFillLocation(state) {
            const storedLocation = JSON.parse(
                localStorage.getItem(USER_FILTER_VALUES)
            );
            if (storedLocation) {
                state.latitude = storedLocation.latitude;
                state.longitude = storedLocation.longitude;
                state.address = storedLocation.address;
                state.place = storedLocation.place;
            } else {
                state.latitude = 9.9312328;
                state.longitude = 76.26730409999999;
                state.address = "Kochi, Kerala, India";
                state.place = "Kochi";
                // state.latitude = null;
                // state.longitude = null;
                // state.address = null;
                // state.place = null;
            }
        },
    }
});

export const filterActions = filterSlice.actions;
export default filterSlice.reducer;
