import React from "react";
import classes from "./Checkout.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import MapIconGray from "components/UI/icons/MapIconGray";
import RemoveIconCircle from "components/UI/icons/RemoveIconCircle";
import PriceDetail from "./PriceDetail";
import PaymentOptions from "./PaymentOptions";

const sampleCart = [
  {
    name: "The Dream Arena , Kochi",
    city: "Kochi , Kerala",
    image: "/img/cart-img.png",
    date: "12 th July , 2023",
    time: "5 pm - 11 pm",
    totalAmount: 120000,
    isPackage: false,
    addOns: [
      { name: "AC", price: 40000 },
      { name: "Rounded Tables", price: 20000 },
    ],
  },
  {
    name: "Marshal Photography",
    city: "Kochi , Kerala",
    image: "/img/cart-img.png",
    date: "12 th July , 2023",
    time: "5 pm - 11 pm",
    totalAmount: 120000,
    isPackage: true,
  },
];

const Cart = () => {
  const windowWidth = useWindowWidth();
  return (
    <div className={classes.container}>
      {/* Title Section */}
      <div className={classes.titleSection}>
        <h1 className={classes.title}>Checkout</h1>
      </div>

      {/* Data section */}
      <div className={classes.cartWrapper}>
        {/* Cart Items */}
        <div className={classes.cartItemsList}>
          {sampleCart?.map((item) => (
            <div className={classes.cartItem}>
              <div className={classes.details}>
                <h1 className={classes.itemName}>{item.name}</h1>
                <div className={classes.cityWrap}>
                  <MapIconGray className={classes.cityIcon} />
                  <h2 className={classes.city}>{item.city}</h2>
                </div>
                <br className={classes.breakLine} />

                {/* section 2 */}
                <div className = {classes.dateAndTime}>
                <h1 className={classes.eventDate}>Event Date : {item.date}</h1>
                <h1 className={classes.eventDate}>Timing : {item.time}</h1>
                </div>
                {item?.addOns && (
                  <div className={classes.addonsData}>
                    <h3 className={classes.addOnTitle}>Addons:</h3>
                    {item?.addOns?.map((addOnItem) => (
                      <div className={classes.addOnItem}>
                        <h1 className={classes.addOnItemName}>
                          {addOnItem.name}:{" "}
                          <span className={classes.addOnItemprice}>
                            ₹&nbsp;{addOnItem.price?.toLocaleString("en-IN")}
                          </span>
                        </h1>
                        {/* <div className={classes.removeWrap}>
                          <RemoveIconCircle />
                          {(windowWidth < 747 || windowWidth >= 1200) && (
                            <h1 className={classes.removeWord}>REMOVE</h1>
                          )}
                        </div> */}
                      </div>
                    ))}
                  </div>
                )}
                <br className={classes.breakLine} />

                {/* Bottom section */}
                <div className={classes.bottomSection}>
                  <h1 className={classes.totalAmount}>
                    Total Amount :&nbsp;&nbsp;
                    <span className={classes.amountTag}>
                      ₹ {item.totalAmount?.toLocaleString("en-IN")}
                    </span>
                  </h1>
                  
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className = {classes.rightDiv}>
        <div className = {classes.paymentDetails}>
          <PaymentOptions />
        </div>

        {/* Price details for desktop screen */}
        
          <div className={classes.priceDetails}>
            <PriceDetail />
          </div>
        
      </div>
      {/* Price details for mobile screen */}
      {/* {windowWidth < 1024 && (
        <div className={classes.priceDetails}>
          <PriceDetail />
        </div>
      )} */}
      </div>
    </div>
  );
};

export default Cart;
