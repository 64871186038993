import React, { useState } from "react";
import classes from "./BookingCards.module.css";
import CreateReviewModal from "../CreateReview/CreateReview";
import useWindowWidth from "helpers/useWindowWidth";
import CancelOrder from "../CancelOrder/CancelOrder";

function BookingCards({ data, timeStatus, fetchBookings }) {
  const [modalVisible, setModalVisible] = useState(false);
  const [cancelVisible, setCancelVisible] = useState(false);
  const width = useWindowWidth();

  console.log("booking data in detail page", data);

  const handleOpenModal = () => {
    setModalVisible(true);
  };

  const handleCancel = () => {
    setCancelVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
    setCancelVisible(false);
  };

  const handleSubmitReview = (reviewData) => {
    // Handle submitting review data
    console.log("Review Data:", reviewData);
    // setModalVisible(false);
  };

  const getPaymentStatus = (status) => {
    if (status == "Completed") {
      return <span className={classes.paymentGreen}>Completed</span>;
    }
    if (status == "Pending") {
      return <span className={classes.paymentRed}>Pending</span>;
    }
  };

  return (
    <>
      <div className={classes.outerWrapper}>
        <div className={classes.cardWrapper}>
          <img src={data.img} />
          <div className={classes.detailsContainer}>
            <div className={classes.bookingNumber}>
              <p>#Booking Number : {data?.bookingNumber}</p>
            </div>
            <div className={classes.timeSection}>
              <h2>
                Event Date :{" "}
                <span className={classes.titleDate}>{data.date}</span>
              </h2>
              <div className={classes.timeAdjust}>
                <span className={classes.redDot}></span>
                <h3 className={classes.timeSchedule}>Timing : </h3>
                &nbsp;<span className={classes.exactTime}>{data.timing}</span>
              </div>
              <div className={classes.upcomingText}>
                <p
                  style={{
                    backgroundColor:
                      timeStatus === "Pending"
                        ? "#2cc13b"
                        : timeStatus === "Cancelled"
                        ? "#E38D3E"
                        : "rgb(255, 102, 102)",
                  }}
                >
                  {timeStatus == "Pending"
                    ? "Pending"
                    : timeStatus == "Confirmed"
                    ? "Confirmed"
                    : timeStatus == "Completed"
                    ? "Completed"
                    : "Cancelled"}
                </p>
              </div>
            </div>
            <div className={classes.addressSection}>
              <div className={classes.leftDiv}>
                <h2 className={classes.eventTitle}>{data.title}</h2>
                <div className={classes.locationSection}>
                  <img src="/img/location-icon.svg" />
                  <p>{data.location}</p>
                </div>
                <p className={classes.addressDetails}>{data.address}</p>
                <div className={classes.capacitySection}>
                  {data.details?.map((item) => (
                    <div className={classes.capacityDiv}>{item}</div>
                  ))}
                </div>
              </div>
              <div className={classes.rightDiv}>
                <button>
                  Payment Status :&nbsp;
                  <span>{getPaymentStatus(data.paymentStatus)}</span>
                </button>

                {(timeStatus === "Confirmed" || timeStatus === "Completed") && (
                  <button onClick={handleOpenModal}>Write a Review</button>
                )}
                {timeStatus == "Pending" && (
                  <button onClick={handleCancel}>Cancel Order</button>
                )}
              </div>
            </div>
            <div
              className={
                width > 1260 ? classes.priceSection : classes.priceHide
              }
            >
              <h3>
                Order Date :{" "}
                <span className={classes.exactTime}>{data.orderDate}</span>
              </h3>
              <h3>
                Name : <span className={classes.exactTime}>{data.name}</span>
              </h3>
              <h3>
                Total :{" "}
                <span className={classes.exactPrice}>
                  ₹{data.price.toLocaleString("en-IN")}
                </span>
              </h3>
            </div>
          </div>
        </div>
        <div className={classes.priceBottomSection}>
          <h3>
            Order Date :{" "}
            <span className={classes.exactTime}>{data.orderDate}</span>
          </h3>
          <h3>
            Name : <span className={classes.exactTime}>{data.name}</span>
          </h3>
          <h3>
            Total :{" "}
            <span className={classes.exactPrice}>
              ₹{data.price.toLocaleString("en-IN")}
            </span>
          </h3>
        </div>
      </div>
      <CreateReviewModal
        visible={modalVisible}
        setModalVisible={setModalVisible}
        onCancel={handleCloseModal}
        onSubmit={handleSubmitReview}
        bookingId={data?.id}
        itemId={data?.itemId}
      />
      <CancelOrder
        visible={cancelVisible}
        setVisible={setCancelVisible}
        onCancel={handleCloseModal}
        bookingId={data?.id}
        itemId={data?.itemId}
        fetchBookings = {fetchBookings}
      />
    </>
  );
}

export default BookingCards;
