import React, { useEffect, useState } from "react";
import classes from "./styles/Cart.module.css";
import useWindowWidth from "helpers/useWindowWidth";
import PriceDetail from "components/page-content/cart-content/PriceDetail";
import MapIconGray from "components/UI/icons/MapIconGray";
import RemoveIconCircle from "components/UI/icons/RemoveIconCircle";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { CartDataKey, VenueDetailDataKey } from "helpers/queriesDataKeys";
import { API_BASE_URL } from "configs/AppConfig";
import { message } from "antd";
import LoadingSpinner from "components/UI/loading-spinner/LoadingSpinner";
import moment from "moment";
import { modalActions } from "store-redux/modal-slice";
import { cartActions } from "store-redux/cart-slice";
import Button from "components/UI/button/Button";

const sampleCart = [
  {
    name: "The Dream Arena , Kochi",
    city: "Kochi , Kerala",
    image: "/img/cart-img.png",
    date: "12 th July , 2023",
    totalAmount: 120000,
    isPackage: false,
    addOns: [
      { name: "AC", price: 40000 },
      { name: "Rounded Tables", price: 20000 },
    ],
  },
  {
    name: "Marshal Photography",
    city: "Kochi , Kerala",
    image: "/img/cart-img.png",
    date: "12 th July , 2023",
    totalAmount: 120000,
    isPackage: true,
  },
];

const Cart = () => {
  const windowWidth = useWindowWidth();
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const filters = useSelector((state) => state.filter);
  const [cartData, setcartData] = useState();
  const cart = useSelector((state) => state.cart);
  console.log("cart data in redux in cart page", cart);
  const [bookingLoading, setBookingLoading] = useState(false);
  const [clearLoading, setClearLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "signin",
        })
      );
    } else if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  }, [isLoggedIn]);

  const {
    isLoading,
    data,
    refetch: refetchCart,
  } = useQuery(
    [CartDataKey, user],
    async () => {
      const response = await fetch(`${API_BASE_URL}cart/list/all`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${user.sessionToken}`,
        },
      });
      const data = await response.json();

      if (!response.ok) {
        setcartData([]);
        // message.error(data.message);
        throw new Error(data.message);
      }

      dispatch(cartActions.toggleCart({ cartTotal: data.total }));
      setcartData(data.data);
      return data.data;
    }
    // {
    //   retry: 0, // Will retry failed requests 10 times before displaying an error
    //   retryDelay: 1000,
    //   initialData: [],
    // }
  );
  console.log("cart data from api", cartData);

  useEffect(() => {
    if (!cartData?.length > 0) {
      //to empty the cart after successfull booking
      dispatch(cartActions.emptyCart());
    }
  }, [cartData]);

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US", options);
  };

  const removeItemFromCart = async (id) => {
    const response = await fetch(`${API_BASE_URL}cart/${id}/delete`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();
    console.log("similar categories -", data);

    if (data.success) {
      refetchCart();
      message.success("Item successfully removed from Cart");
    }

    if (!response.ok) {
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const removeAddOnFromCartItem = async (id, cartItem, addOnToRemove) => {
    const updatedAddonList = cartItem?.addOns?.filter(
      (item) => item != addOnToRemove
    );
    let payload = {
      selectedDate: cartItem?.selectedDate,
      addOns: updatedAddonList,
    };
    if (cartItem?.start) payload.start = cartItem?.start;
    if (cartItem?.end) payload.end = cartItem?.end;
    if (cartItem?.slotNo) payload.slotNo = cartItem?.slotNo;
    if (cartItem?.slots) payload.slots = cartItem?.slots;
    console.log("payload to remove add on", payload);

    const response = await fetch(`${API_BASE_URL}cart/${id}/update`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify(payload),
    });
    const data = await response.json();

    if (data.success) {
      refetchCart();
      message.success("AddOn removed successfully");
    }

    if (!response.ok) {
      message.error(data.message);
      throw new Error(data.message);
    }
  };

  const bookNow = async () => {
    setBookingLoading(true);
    const response = await fetch(`${API_BASE_URL}booking/create/customer`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
      body: JSON.stringify({
        paymentMode: "Offline",
      }),
    });
    const data = await response.json();
    console.log("similar categories -", data);

    if (data.success) {
      refetchCart();
      message.success("Booking created successfully...");
      setBookingLoading(false);
    }

    if (!response.ok) {
      message.error(data.message);
      setBookingLoading(false);
      throw new Error(data.message);
    }
  };

  const clearCart = async () => {
    setClearLoading(true);
    const response = await fetch(`${API_BASE_URL}cart/delete/all`, {
      method: "DELETE",
      headers: {
        // "Content-Type": "application/json",
        // Accept: "/",
        Authorization: `Bearer ${user.sessionToken}`,
      },
    });
    const data = await response.json();

    if (data.success) {
      setClearLoading(false);
      refetchCart();
      message.success("Cleared cart successfully");
    }

    if (!response.ok) {
      message.error(data.message);
      setClearLoading(false);
      throw new Error(data.message);
    }
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}
      {!isLoading && cartData && (
        <div className={classes.container}>
          {/* Title Section */}
          <div className={classes.titleSectionWrapper}>
            <div className={classes.titleSection}>
              <h1 className={classes.title}>Cart</h1>
              <h1 className={classes.desc}>
                Secure your ideal event space effortlessly
              </h1>
            </div>
            <Button
              option="colored"
              className={classes.clearButton}
              onClick={() => clearCart()}
              loading={clearLoading}
              disabled={clearLoading}
            >
              Clear Cart
            </Button>
          </div>

          {/* Data section */}
          <div className={classes.cartWrapper}>
            {/* Cart Items */}

            <div className={classes.cartItemsList}>
              {!cartData.length > 0 && (
                <h4 className={classes.emptyCart}>Your Cart is empty</h4>
              )}
              {cartData?.map((item) => (
                <div className={classes.cartItem}>
                  <div className={classes.cartItemImageContainer}>
                    <img
                      src={item.listingImages[0]}
                      alt="item"
                      className={classes.cartItemImage}
                    />
                    {/* {item.isPackage && (
                  <div className={classes.tag}>Package Deal</div>
                )} */}
                  </div>
                  <div className={classes.details}>
                    <h1 className={classes.itemName}>{item.listingName}</h1>
                    <div className={classes.cityWrap}>
                      <MapIconGray className={classes.cityIcon} />
                      <h2 className={classes.city}>
                        {item.listingLocation?.locationName}
                      </h2>
                    </div>
                    <br className={classes.breakLine} />

                    {/* section 2 */}
                    <h1 className={classes.eventDate}>
                      Event Date : {formatDate(item.selectedDate)}
                    </h1>
                    <h1 className={classes.eventDate2}>
                      Timing : {moment(item?.start, "HH:mm")?.format("h:mm A")}{" "}
                      - {moment(item?.end, "HH:mm")?.format("h:mm A")}
                    </h1>
                    {item?.addOns?.length > 0 && (
                      <div className={classes.addonsData}>
                        <h3 className={classes.addOnTitle}>Addons:</h3>
                        {item?.addOns?.map((addOnItem) => (
                          <div className={classes.addOnItem}>
                            <h1 className={classes.addOnItemName}>
                              {addOnItem.name}:{" "}
                              <span className={classes.addOnItemprice}>
                                ₹&nbsp;
                                {addOnItem.charge?.toLocaleString("en-IN")}
                              </span>
                            </h1>
                            <div
                              className={classes.removeWrap}
                              onClick={() =>
                                removeAddOnFromCartItem(
                                  item._id,
                                  item,
                                  addOnItem
                                )
                              }
                            >
                              <RemoveIconCircle />
                              {(windowWidth < 747 || windowWidth >= 1200) && (
                                <h1 className={classes.removeWord}>REMOVE</h1>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <br className={classes.breakLine} />

                    {/* Bottom section */}
                    <div className={classes.bottomSection}>
                      <h1 className={classes.totalAmount}>
                        Total Amount :&nbsp;&nbsp;
                        <span className={classes.amountTag}>
                          ₹ {item.price?.toLocaleString("en-IN")}
                        </span>
                      </h1>
                      <h2
                        className={classes.removeButton}
                        onClick={() => removeItemFromCart(item._id)}
                      >
                        REMOVE ITEM
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            {/* Price details for desktop screen */}
            {windowWidth >= 1024 && (
              <div className={classes.priceDetails}>
                <PriceDetail
                  cartData={cartData}
                  bookNow={bookNow}
                  bookingLoading={bookingLoading}
                />
              </div>
            )}
          </div>
          {/* Price details for mobile screen */}
          {windowWidth < 1024 && (
            <div className={classes.priceDetails}>
              <PriceDetail
                cartData={cartData}
                bookNow={bookNow}
                bookingLoading={bookingLoading}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
